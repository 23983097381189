import Cookie from "js-cookie";

console.log(Cookie.get("times_visited"));
// height of page
const front_page_loader = document.querySelector(".frontpage_loader");
const home_page_project_grid = document.documentElement;
const times_visted_site = Cookie.get("times_visited");

function start_loader() {
  if (front_page_loader !== null) {
    const page_height = home_page_project_grid.offsetHeight;
    front_page_loader.style.height = page_height + "px";
  }

  front_page_loader.classList.add("enable_loader");

  window.setTimeout(function () {
    disable_loader();
  }, 4250);
}

function disable_loader() {
  front_page_loader.classList.add("disable_loader");
}

if (times_visted_site == 5) {
  Cookie.set("times_visited", 5, { expires: 60 });

  disable_loader();
}

if (times_visted_site < 5) {
  let value = Cookie.get("times_visited");
  value = ~~value + 1;
  Cookie.set("times_visited", value, { expires: 60 });
  start_loader();
}

if (times_visted_site == undefined) {
  Cookie.set("times_visited", 1, { expires: 60 });

  start_loader();
}

console.log(Cookie.get("times_visited"));
